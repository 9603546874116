export const ShorebirdLogo = ({ className }: { className?: string }) => (
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    className={className ?? 'size-10'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M139.052 65.4199L126.123 52.4912C126.862 55.091 128.644 63.0916 128.747 64.3718L139.052 65.4199Z"
      fill="#A79772"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.597 100.387L116.704 100.249L116.8 100.103C126.947 84.616 130.345 71.166 129.042 59.5733C127.739 47.976 121.807 38.9047 114.596 32.0332C100.361 18.4695 80.3496 12.7185 77.0111 11.8214C76.0838 11.5722 74.9843 11.5973 73.934 12.092C72.9155 12.5716 72.2317 13.3564 71.8401 14.1372C71.0854 15.6421 71.256 17.3611 72.0956 18.6578L75.0629 23.2411C74.2194 23.3875 73.3586 23.6217 72.4851 23.9577C66.0241 26.4427 60.8496 33.8211 57.3164 47.0707C53.315 62.0761 45.4709 69.718 37.9107 73.7343C30.1853 77.8384 22.3784 78.3482 18.3847 78.3482C14.9863 78.3482 12.1829 77.2865 10.1966 76.1908C9.20776 75.6453 8.44702 75.1049 7.95066 74.717C7.7036 74.5239 7.52555 74.3713 7.42108 74.2786C7.36895 74.2324 7.3355 74.2013 7.3213 74.188L7.31628 74.1833C5.6877 72.597 3.104 72.5482 1.41647 74.0767C-0.278416 75.612 -0.479058 78.2037 0.959347 79.9815L4.30944 77.271C0.959347 79.9815 0.960531 79.983 0.961957 79.9847L0.96603 79.9898L0.978549 80.0052L1.02098 80.0571C1.05695 80.1011 1.10826 80.1635 1.17459 80.2436C1.30725 80.4038 1.50003 80.6346 1.75043 80.9295C2.25116 81.5191 2.9828 82.3654 3.92527 83.4154C5.8092 85.5142 8.54135 88.4332 11.9611 91.7461C18.7773 98.3493 28.433 106.623 39.6366 113.06C50.8044 119.476 63.8675 124.273 77.3738 123.388C91.0849 122.489 104.628 115.775 116.597 100.387ZM12.5574 86.2767C7.3435 81.0205 4.30965 77.2708 4.30965 77.2708C4.30965 77.2708 4.3098 77.271 4.31012 77.2713C4.68504 77.7336 68.0776 155.747 113.194 97.7397C129.458 72.9163 126.839 54.8935 117.773 42.1856C126.84 54.8936 129.46 72.9168 113.195 97.7413C77.8252 143.217 31.2224 105.093 12.5574 86.2767Z"
      fill="url(#paint0_linear_3009_31838)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.6286 57.204C53.547 51.8281 59.2416 48.1782 65.7887 48.1782C74.4561 48.1782 81.6294 54.5749 82.8454 62.905C86.744 75.8921 68.289 91.4738 50.9199 91.4738C35.6219 91.4738 10.4038 80.0502 20.7528 69.9259C23.9432 66.8048 27.5046 66.8209 31.3214 66.8382C34.1392 66.851 37.0963 66.8644 40.1459 65.6163C43.8595 64.0966 46.3081 61.5855 48.661 59.1725C49.3159 58.5009 49.9634 57.8369 50.6286 57.204Z"
      fill="#A78F72"
    />
    <path
      d="M104.402 59.6216C100.832 59.6216 97.9375 56.7274 97.9375 53.1572C97.9375 49.5871 100.832 46.6929 104.402 46.6929C107.972 46.6929 110.866 49.5871 110.866 53.1572C110.866 56.7274 107.972 59.6216 104.402 59.6216Z"
      fill="#161619"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3009_31838"
        x1="52.7516"
        y1="57.0519"
        x2="111.315"
        y2="108.386"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D1C2B0" />
        <stop offset="1" stopColor="#FBEDDD" />
      </linearGradient>
    </defs>
  </svg>
);
